import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import { BodyLarge, Body } from "../typography"

const Wrapper = styled.div`
  max-width: 1040px;

  margin: 0 auto;

  padding: 40px 24px;

  position: relative;

  color: ${colors.creamLight};

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  ${BodyLarge} {
    margin-bottom: 16px;
  }

  @media ${breakpoints.tablet} {
    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 80px;

    > p {
      max-width: 480px;
    }
  }
`

export default function BodyComponent({ large, text }) {
  return (
    <Wrapper>
      <BodyLarge>{large}</BodyLarge>
      <Body>{text}</Body>
    </Wrapper>
  )
}
