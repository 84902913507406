import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import PrimaryButton from "../common/PrimaryButton"
import { BodyLarge } from "../typography"

import HeroImg from "../../images/hero.png"
import breakpoints from "../../styles/breakpoints"

const Wrapper: any = styled.div`
  padding: 100px 24px;
  text-align: center;
  color: ${colors.creamLight};

  background-image: url(${(props: any) =>
    props.image ? props.image : HeroImg});
  background-size: cover;
  background-position: center;

  ${BodyLarge} {
    max-width: 800px;
    margin: 16px auto;
  }

  ${PrimaryButton} {
    margin: 32px 0;
    color: ${colors.creamLight};
    background: ${colors.pumpkinDark};
    &:hover {
      background: ${colors.pumpkinLight};
      color: ${colors.firDark};
    }
  }

  @media ${breakpoints.tablet} {
    padding: 275px 24px;
  }

  border-bottom: 1px solid ${colors.creamLight};
`

const HeaderImage = styled.div``

const Headline = styled.h1`
  font-family: "Nib Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  /* or 53px */

  text-align: center;

  color: ${colors.creamLight};

  @media ${breakpoints.tablet} {
    font-family: "Nib Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 88px;
    line-height: 110%;
    /* identical to box height, or 97px */

    text-align: center;
    margin: 0;
  }
`

export default function Header({ image }) {
  return (
    <Wrapper image={image}>
      <Headline>Our Story</Headline>
    </Wrapper>
  )
}
