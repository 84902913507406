import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import richText from "../../utils/richText"
import { Body, BodyLarge, FeaturedLarge } from "../typography"

const Wrapper = styled.div`
  color: ${colors.creamLight};

  position: relative;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    max-width: 1440px;
    margin: 0 auto;

    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`

const VisionImageWrapper = styled.div`
  position: relative;

  padding: 0px 24px 40px;

  &:after {
    content: "";

    position: absolute;
    left: -24px;
    bottom: 0;

    transform: translateY(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  text-align: center;

  @media ${breakpoints.tablet} {
    border: 0;

    padding: 40px 24px;

    border-right: 1px solid ${colors.creamLight};

    &:after {
      display: none;
    }

    .gatsby-image-wrapper {
      width: 640px;
      height: 640px;
    }
  }
`

const VisionText = styled(FeaturedLarge)`
  margin: 40px 0;

  @media ${breakpoints.tablet} {
    margin-bottom: 130px;

    margin-top: 0;
  }

  > span {
    display: block;
    width: 100%;

    &:last-child {
      text-align: right;
    }
  }
`

const VisionBodyWrapper = styled.div`
  padding: 24px 40px;

  > div,
  p {
    max-width: 400px;
  }

  > ${VisionText} {
    max-width: unset;
  }

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export default function Vision({ image, large, text }) {
  return (
    <Wrapper>
      <VisionImageWrapper>
        <GatsbyImage image={image} alt="Vision Image" />
      </VisionImageWrapper>
      <VisionBodyWrapper>
        <VisionText as="h2">
          <span>The</span>
          <span>Vision</span>
        </VisionText>
        <div>
          <BodyLarge>{large}</BodyLarge>
          <Body as="div">{richText(text)}</Body>
        </div>
      </VisionBodyWrapper>
    </Wrapper>
  )
}
