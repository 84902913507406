import React from "react"
import { Body, Header2, Header3, Header4 } from "../components/typography"

function renderContent(content) {
  switch (content.nodeType) {
    case "paragraph":
      return <Body>{content.content.map(renderContent)}</Body>
    case "hyperlink":
      return <a href={content.data.uri}>{content.content.map(renderContent)}</a>
    case "list-item":
      return <Body as="li">{content.content.map(renderContent)}</Body>
    case "unordered-list":
      return <ul>{content.content.map(renderContent)}</ul>
    case "ordered-list":
      return <ol>{content.content.map(renderContent)}</ol>
    case "text":
      let textNode = content.value

      content.marks.forEach(mark => {
        if (mark.type === "bold") {
          textNode = <strong>{textNode}</strong>
        } else if (mark.type === "italic") {
          textNode = <i>{textNode}</i>
        } else if (mark.type === "underline") {
          textNode = <u>{textNode}</u>
        }
      })

      return textNode
    default:
      return content.value
  }
}

export default function richText(body) {
  const bodyObj = JSON.parse(body)

  return bodyObj.content.map(block => {
    switch (block.nodeType) {
      case "heading-1":
        return <Header2>{block.content.map(renderContent)}</Header2>
      case "heading-2":
        return <Header3>{block.content.map(renderContent)}</Header3>
      case "heading-3":
        return <Header4>{block.content.map(renderContent)}</Header4>
      case "heading-4":
        return <Header4>{block.content.map(renderContent)}</Header4>
      case "heading-5":
        return <Header4>{block.content.map(renderContent)}</Header4>
      case "unordered-list":
        return <ul>{block.content.map(renderContent)}</ul>
      case "ordered-list":
        return <ol>{block.content.map(renderContent)}</ol>
      // case "blockquote":
      //   return (
      //     <ArticleBlockQuote>
      //       {block.content.map(renderContent)}
      //     </ArticleBlockQuote>
      //   );
      // case "embedded-asset-block":
      // return <Image src={block.data.target.fields.file["en-US"].url} />;
      default:
        return <Body>{block.content.map(renderContent)}</Body>
    }
  })
}
