import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import PrimaryButton from "../common/PrimaryButton"
import California from "../icons/California"
import { Body, BodyMedium } from "../typography"

const Wrapper = styled.div`
  display: flex;

  flex-direction: column-reverse;

  border: 1px solid ${colors.creamLight};

  margin: 40px 24px;

  max-width: 960px;

  @media ${breakpoints.tablet} {
    flex-direction: row;

    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 80px auto;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  background: ${colors.firLight};

  padding: 30px 24px;

  @media ${breakpoints.tablet} {
    padding: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    flex: 0 1 50%;

    border-right: 1px solid ${colors.creamLight};
  }

  svg {
    margin-bottom: 16px;
  }

  ${BodyMedium} {
    color: ${colors.creamLight};
    margin-bottom: 16px;

    @media ${breakpoints.tablet} {
      margin-bottom: 24px;
    }
  }

  ${PrimaryButton} {
    background: ${colors.pumpkinDark};
    color: ${colors.creamLight};

    &:hover {
      background: ${colors.pumpkinLight};
    }
  }
`

const ImageWrapper = styled.div`
  padding: 30px;

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    flex: 0 0 50%;

    display: flex;

    justify-content: center;
    align-items: center;

    .gatsby-image-wrapper {
      max-width: 400px;
    }
  }
`

export default function ShopAll({ text, image }) {
  return (
    <div
      style={{
        margin: "0 auto",
        borderBottom: `1px solid ${colors.creamLight}`,
      }}
    >
      <Wrapper>
        <TextWrapper>
          <California />
          <BodyMedium>{text}</BodyMedium>
          <PrimaryButton as={Link} to="/shop">
            Shop All
          </PrimaryButton>
        </TextWrapper>
        <ImageWrapper>
          <GatsbyImage alt="Shop All Dromme" image={image} />
        </ImageWrapper>
      </Wrapper>
    </div>
  )
}
