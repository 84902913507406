import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/our-story/Header"
import Body from "../components/our-story/Body"
import Vision from "../components/our-story/Vision"
import ShopAll from "../components/our-story/ShopAll"
import Seo from "../components/seo"

export const query = graphql`
  query OurStory {
    allContentfulOurStory {
      nodes {
        headerBanner {
          url
        }
        largeMissionStatement {
          largeMissionStatement
        }
        smallMissionStatement {
          smallMissionStatement
        }
        largeVisionText {
          largeVisionText
        }
        visionImage {
          gatsbyImage(width: 640, height: 800, placeholder: BLURRED)
        }
        visionTextBody {
          raw
        }
        shopAllImage {
          gatsbyImage(width: 400, height: 400, placeholder: BLURRED)
        }
        shopAllText {
          shopAllText
        }
      }
    }
  }
`

export function Head() {
  return <Seo title={"Our Story"} />
}

export default function OurStory({ data }) {
  const page = data.allContentfulOurStory.nodes[0]

  return (
    <Layout>
      <Header image={page.headerBanner.url} />
      <Body
        large={page.largeMissionStatement.largeMissionStatement}
        text={page.smallMissionStatement.smallMissionStatement}
      />
      <Vision
        image={page.visionImage.gatsbyImage}
        large={page.largeVisionText.largeVisionText}
        text={page.visionTextBody.raw}
      />
      <ShopAll
        text={page.shopAllText.shopAllText}
        image={page.shopAllImage.gatsbyImage}
      />
    </Layout>
  )
}
